<template>
  <div class="container">
    <ProgressHeader step="ssn" />

    <div class="py-8">
      <div class="flex">
        <label for="ssn" class="block text-sm font-medium leading-6 text-gray-900">Social Security Number</label>
        <QuestionMarkCircleIcon @click="showIntercomArticle" class="w-4 ml-1.5 text-gray-400" />
      </div>
      <div class="mt-2">
        <div class="relative mt-2 rounded-md shadow-sm">
          <input :type="showingTaxId ? 'text' : 'password'" autocomplete="off" v-model="taxId" inputmode="numeric" maxlength="9" ref="ssn" name="ssn" id="ssn" class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="000-00-0000" />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3" @click="toggleTaxId">
            <EyeIcon v-if="!showingTaxId" class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <EyeSlashIcon v-else class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      <p class="mt-2 text-sm text-red-600" v-if="ssnError">{{ ssnError }}</p>
    </div>

    <div class="bg-gray-50 rounded-lg mb-8">
      <div class="flex items-center px-4 py-5 sm:p-6">
        <LockClosedIcon class="w-7 text-gray-400" />
        <div class="max-w-xl pl-4 text-sm text-gray-500">
          <p>We use bank-level encryption to protect your information.</p>
        </div>
      </div>
    </div>

    <Combobox class="pb-12" as="div" v-model="country" @update:modelValue="query = ''">
      <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">Citizenship</ComboboxLabel>
      <div class="relative mt-2">
        <ComboboxInput autocomplete="country-name" class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" @change="query = $event.target.value" @blur="query = ''" :display-value="(person) => person?.name" />
        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        <ComboboxOptions v-if="filteredCountries.length > 0" class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ComboboxOption v-for="country in filteredCountries" :key="country.id" :value="country" as="template" v-slot="{ active, selected }">
            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-ig-blue text-white' : 'text-gray-900']">
              <div class="flex items-center">
                <span :class="['ml-3 truncate', selected && 'font-semibold']">
                {{ country.name }}
              </span>
              </div>

              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-ig-blue']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
      <p class="mt-2 text-sm text-gray-500" id="email-description">If you hold dual citizenship with the United States, please select United States.</p>
      <p class="mt-2 text-sm text-red-600" v-if="nationalityError">{{ nationalityError }}</p>
    </Combobox>

    <button
        @click="save"
        type="button"
        :disabled="saving"
        class="text-white rounded-md w-full bg-ig-blue px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ig-blue"
    >
      Continue
    </button>

  </div>
</template>

<script setup>
import ProgressHeader from '@/components/ProgressHeader'
import { computed, onMounted, ref, defineEmits, defineProps } from 'vue'
import { showArticle } from '@intercom/messenger-js-sdk'
import { API } from '@/API'
import { CheckIcon, ChevronUpDownIcon, LockClosedIcon, QuestionMarkCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import countries from '../assets/countries.json'

const emit = defineEmits(['complete'])
const props = defineProps(['user'])
const query = ref('')
const ssn = ref(null)
const taxId = ref(props.user.tax_id)
const showingTaxId = ref(false)
const saving = ref(false)
const ssnError = ref(null)
const nationalityError = ref(null)

const country = ref(countries.find((country) => country.id === 'us'))
const filteredCountries = computed(() =>
  query.value === ''
      ? countries
      : countries.filter((country) => {
        return country.name.toLowerCase().includes(query.value.toLowerCase())
      })
)

const showIntercomArticle = () => {
  showArticle(6630016)
}

const validate = () => {
  let saves = true
  ssnError.value = null
  nationalityError.value = null

  if(!(/^\d{9}$/).test(taxId.value) && (props.user.tax_id != taxId.value && taxId.value != null)) {
    ssnError.value = 'Please enter a valid SSN'
    saves = false
  }

  if(!countries.map((country) => country.id).includes(country.value.id)) {
    nationalityError.value = 'Please select your citizenship'
    saves = false
  }

  return saves
}

const toggleTaxId = () => {
  showingTaxId.value = !showingTaxId.value
}

const save = async () => {
  if(!validate())
    return

  try {
    saving.value = true
    const toSave = {
      tax_id: (props.user.tax_id != taxId.value ? taxId.value : undefined),
      nationality: (props.user.nationality != country.value.id ? country.value.id : undefined)
    }
    if(toSave.tax_id || toSave.nationality)
      await new API().save(toSave)
    await emit('complete')
  } catch (e) {
    alert('Could not save. Please check your input and try again.')
  } finally {
    saving.value = false
  }
}

onMounted(() => {
  ssn.value.focus()
})

</script>
