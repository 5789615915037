<template>
  <div class="h-full w-full" id="onfido-mount"></div>
</template>

<script setup>
import { Onfido } from 'onfido-sdk-ui'
import { defineProps, defineEmits, onMounted } from 'vue'

const emit = defineEmits(['complete'])
const props = defineProps({
  onfidoToken: String,
  workflowRunId: String
})

onMounted(() => {
  Onfido.init({
    token: props.onfidoToken,
    workflowRunId: props.workflowRunId,
    containerId: 'onfido-mount',
    language: {
      locale: 'en',
      phrases: {
        'common.permissions.primer.video.subtitle': 'Please select "Allow" when prompted to continue',

        'document.preview.title': 'Does everything look okay?',
        'document.preview.upload': 'Yes',

        'motion.intro.title': 'Let\'s make sure it\'s you',
        'motion.intro.subtitle': 'You\'ll take a quick selfie to verify that you match the person on your ID',
        'motion.intro.disclaimer': 'This process is automated and your selfie is only used to verify your identity',
        'motion.intro.button_primary_ready': 'I\'m ready',

        'motion.confirmation.title': 'Thanks! You can put your ID away now',
        'motion.confirmation.subtitle': 'There are just a few more steps to verify your identity',
        'motion.confirmation.button_primary_upload': 'Continue'
      }
    },
    onComplete: function () { emit('complete') },
    onError: function (e) { console.log(e) },
    enterpriseFeatures: {
      hideOnfidoLogo: true
    },
    customUI: {
      "googleFonts": ["Inter"],
      "borderRadiusButton": "0.375rem",
      "borderStyleSurfaceModal": "0px",
      "fontWeightBody": 400,
      "fontFamilyTitle": "Inter",
      "fontFamilySubtitle": "Inter",
      "fontFamilyBody": "Inter",
      "fontSizeBody": "0.875rem",
      "fontSizeSubtitle": "1rem",
      "fontSizeTitle": "1.125rem",

      "colorBackgroundSurfaceModal": "transparent",

      "colorContentButtonPrimaryText": "rgb(255,255,255)",
      "colorBackgroundButtonPrimary": "rgb(20, 101, 231)",
      "colorBorderButtonPrimary": "rgb(20, 101, 231)",
      "colorBackgroundButtonPrimaryHover": "rgb(40, 133, 243)",
      "colorBackgroundButtonPrimaryActive": "rgb(20, 101, 231)",

      "colorContentButtonSecondaryText": "rgb(17,24,39)",
      "colorBorderButtonSecondary": "rgb(20, 101, 231)",
      "colorBackgroundSelector": "#ffffff",
      "colorBackgroundInput": "#ffffff",
      "colorBorderInput": "#d1d5db"
    }
  })
})
</script>