<template>
  <div class="container">
    <div class="h-screen flex flex-col justify-center">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Thanks {{ props.userFirstName }}!</h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500">We’ve successfully verified your identity. You can return to your computer now.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/24/outline'
import { defineProps } from 'vue'

const props = defineProps(['userFirstName'])
</script>