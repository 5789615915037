import { createApp } from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import App from './App.vue'

Bugsnag.start({
    apiKey: 'd5d7e7fe52ac7fc03244ed6e76347b84',
    plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: 'd5d7e7fe52ac7fc03244ed6e76347b84' })

const bugsnagVue = Bugsnag.getPlugin('vue')
createApp(App).use(bugsnagVue).mount('#app')
