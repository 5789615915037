<template>
  <div class="container">
    <ProgressHeader step="compliance" class="mb-8"/>

    <div class="mb-8" v-for="organization in props.user.metadata.organizations" :key="organization.id">
      <label for="role" class="block text-sm font-medium leading-6 text-gray-900">What is your current role at {{ organization.name }}?</label>
      <div class="mt-2">
        <input type="text" v-model="titles[organization.id]" autocomplete="organization-title" ref="role" name="role" id="role" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="Ex: Executive Director" />
      </div>
      <p class="mt-2 text-sm text-red-600" v-if="errors.role[organization.id]">{{ errors.role[organization.id] }}</p>
    </div>

    <fieldset class="mb-8">
      <legend class="text-sm font-semibold leading-6 text-gray-900">Are you a control person of a publicly traded company?</legend>
      <p class="mt-1 text-sm leading-6 text-gray-600">Example: director, officer, or 10% stock holder</p>
      <div class="mt-2 space-y-4">
        <div class="flex items-center">
          <input id="controlPerson-yes" name="controlPerson" v-model="controlPerson" :value="true" type="radio" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="controlPerson-yes" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Yes</label>
        </div>
        <div class="flex items-center">
          <input id="controlPerson-no" name="controlPerson" v-model="controlPerson" :value="false" type="radio" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="controlPerson-no" class="ml-3 block text-sm font-medium leading-6 text-gray-900">No</label>
        </div>
      </div>
      <p class="mt-2 text-sm text-red-600" v-if="errors.controlPerson">{{ errors.controlPerson }}</p>
      <div class="mt-3" v-if="controlPerson">
        <label for="tickerSymbol" class="text-sm leading-6 text-gray-600">What is the company ticker symbol?</label>
        <div class="mt-2">
          <input type="text" autocomplete="organization" name="tickerSymbol" v-model="affiliatedPubliclyTradedCompanies" id="tickerSymbol" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="Ex: AAPL, TSLA" />
        </div>
        <p class="mt-2 text-sm text-red-600" v-if="errors.affiliatedPubliclyTradedCompanies">{{ errors.affiliatedPubliclyTradedCompanies }}</p>
      </div>
    </fieldset>

    <fieldset class="pb-8">
      <legend class="text-sm font-semibold leading-6 text-gray-900">How much investing experience do you personally have?</legend>
      <div class="mt-2 space-y-4">
        <div class="flex items-center">
          <input id="none" name="investmentExperience" type="radio" value="none" v-model="investmentExperience" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="none" class="ml-3 block text-sm font-medium leading-6 text-gray-900">None</label>
        </div>
        <div class="flex items-center">
          <input id="limited" name="investmentExperience" type="radio" value="limited" v-model="investmentExperience"  class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="limited" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Limited</label>
        </div>
        <div class="flex items-center">
          <input id="good" name="investmentExperience" type="radio" value="good" v-model="investmentExperience" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="good" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Good</label>
        </div>
        <div class="flex items-center">
          <input id="extensive" name="investmentExperience" value="extensive" v-model="investmentExperience" type="radio" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="extensive" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Extensive</label>
        </div>
      </div>
      <p class="mt-2 text-sm text-red-600" v-if="errors.investmentExperience">{{ errors.investmentExperience }}</p>
    </fieldset>

    <fieldset class="mb-8">
      <legend class="text-sm font-semibold leading-6 text-gray-900">Are you affiliated with or work with or work for a member firm of a stock exchange or FINRA?</legend>
      <div class="mt-2 space-y-4">
        <div class="flex items-center">
          <input id="finra-yes" name="finra" type="radio" :value="true" v-model="finra" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="finra-yes" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Yes</label>
        </div>
        <div class="flex items-center">
          <input id="finra-no" name="finra" type="radio" :value="false" v-model="finra" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="finra-no" class="ml-3 block text-sm font-medium leading-6 text-gray-900">No</label>
        </div>
      </div>
      <p class="mt-2 text-sm text-red-600" v-if="errors.finra">{{ errors.finra }}</p>


      <div class="mt-3" v-if="finra">
        <label for="firmName" class="text-sm leading-6 text-gray-600">What is the name of the firm?</label>
        <div class="mt-2">
          <input type="text" v-model="affiliatedMemberFirms" autocomplete="organization" name="firmName" id="firmName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="Ex: Peachtree Advisors LLC" />
        </div>
        <p class="mt-2 text-sm text-red-600" v-if="errors.affiliatedMemberFirms">{{ errors.affiliatedMemberFirms }}</p>
      </div>
    </fieldset>

    <fieldset class="mb-10">
      <legend class="text-sm font-semibold leading-6 text-gray-900">Are you a current or a former politically exposed person or foreign public official?</legend>
      <div class="mt-2 space-y-4">
        <div class="flex items-center">
          <input id="poticallyExposed-yes" name="politicallyExposed" type="radio" :value="true" v-model="politicallyExposed" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="poticallyExposed-yes" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Yes</label>
        </div>
        <div class="flex items-center">
          <input id="poticallyExposed-no" name="politicallyExposed" type="radio" :value="false" v-model="politicallyExposed" class="h-4 w-4 border-gray-300 text-ig-blue focus:ring-ig-blue" />
          <label for="poticallyExposed-no" class="ml-3 block text-sm font-medium leading-6 text-gray-900">No</label>
        </div>
      </div>

      <p class="mt-2 text-sm text-red-600" v-if="errors.politicallyExposed">{{ errors.politicallyExposed }}</p>

      <div class="mt-3" v-if="politicallyExposed">
        <div class="mt-6">
          <label for="politicalOrganization" class="text-sm leading-6 text-gray-600">What is the name of your political organization?</label>
          <div class="mt-1">
            <input type="text" name="politicalOrganization" v-model="politicalOrganization" autocomplete="organization" id="politicalOrganization" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="Ex: Bull Moose Party of Georgia" />
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors.politicalOrganization">{{ errors.politicalOrganization }}</p>
        </div>

        <div class="mt-6">
          <label for="politicalFamily" class="text-sm leading-6 text-gray-600">What are the names of your immediate family members?</label>
          <div class="mt-1">
            <input type="text" name="politicalFamily" v-model="politicalFamily" autocomplete="off" id="politicalFamily" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ig-blue sm:text-sm sm:leading-6" placeholder="Ex: John Smith, Janie Smith" />
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors.politicalFamily">{{ errors.politicalFamily }}</p>
        </div>
      </div>
    </fieldset>

    <button
        @click="save"
        type="button"
        :disabled="saving"
        class="text-white rounded-md mb-10 w-full bg-ig-blue px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-ig-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ig-blue"
    >
      Finish
    </button>
  </div>
</template>

<script setup>
import ProgressHeader from './ProgressHeader'
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import { API } from '@/API'

const emit = defineEmits(['complete'])
const props = defineProps(['user'])
const role = ref(null)
const saving = ref(false)

let orgs = {}
props.user.metadata.organizations.forEach((organization) => {
  orgs[organization.id] = organization.title
})

const titles = ref(orgs)
const controlPerson = ref(props.user.affiliated_publicly_traded_companies != null ? props.user.affiliated_publicly_traded_companies.length > 0 : null)
const investmentExperience = ref(props.user.investment_experience)
const affiliatedMemberFirms = ref(props.user.affiliated_member_firms?.join(', ') || undefined)
const affiliatedPubliclyTradedCompanies = ref(props.user.affiliated_publicly_traded_companies?.join(', ') || null)
const politicallyExposed = ref(props.user.political_exposure != null || null)
const politicalOrganization = ref(props.user.political_exposure?.organization_name)
const politicalFamily = ref(props.user.political_exposure?.immediate_family?.join(', '))

const finra = ref(props.user.affiliated_member_firms != null ? props.user.affiliated_member_firms.length > 0 : null)
const errors = ref({ role: {} })

const validate = () => {
  let saves = true
  errors.value = { role: {} }

  props.user.metadata.organizations.forEach((organization) => {
    if(titles.value[organization.id] == null || titles.value[organization.id].length < 3){
      errors.value.role[organization.id] = 'Please enter your role (more than two characters)'
      saves = false
    }
  })

  if(controlPerson.value == null){
    errors.value.controlPerson = 'Please select if you are a control person of a public company'
    saves = false
  } else if(controlPerson.value && (affiliatedPubliclyTradedCompanies.value == null || affiliatedPubliclyTradedCompanies.value.length < 3)) {
    errors.value.affiliatedPubliclyTradedCompanies = 'Please enter the stock tickers (more than two characters)'
    saves = false
  }

  if(investmentExperience.value == null){
    errors.value.investmentExperience = 'Please select your investment experience'
    saves = false
  }

  if(finra.value == null){
    errors.value.finra = 'Please select if you are affiliated with FINRA'
    saves = false
  } else if (finra.value && (affiliatedMemberFirms.value == null || affiliatedMemberFirms.value.length < 3)){
    errors.value.affiliatedMemberFirms = 'Please enter the name of your firm (more than two characters)'
    saves = false
  }

  if(politicallyExposed.value == null){
    errors.value.politicallyExposed = 'Please select if you are politically exposed'
    saves = false
  }

  if (politicallyExposed.value && (politicalOrganization.value == null || politicalOrganization.value.length < 3)){
    errors.value.politicalOrganization = 'Please enter the name of your political organization'
    saves = false
  }

  if (politicallyExposed.value && (politicalFamily.value == null || politicalFamily.value.length < 3)){
    errors.value.politicalFamily = 'Please enter the name of your family members'
    saves = false
  }

  return saves
}

const save = async () => {
  if(!validate())
    return

  try {
    saving.value = true
    const toSave = {
      organizations: Object.keys(titles.value).map((id) => { return { id, title: titles.value[id] }}),
      investment_experience: (investmentExperience.value),
      affiliated_member_firms: (finra.value ? affiliatedMemberFirms.value.split(',') : []),
      affiliated_publicly_traded_companies: (controlPerson.value ? affiliatedPubliclyTradedCompanies.value.split(',') : []),
      political_exposure: (politicallyExposed.value ? {
        organization_name: politicalOrganization.value,
        immediate_family: politicalFamily.value.split(",")
      } : null),
      confirm: true
    }
    await new API().save(toSave)
    await emit('complete')
  } catch (e) {
    alert('Could not save. Please check your input and try again.')
  } finally {
    saving.value = false
  }
}

onMounted(() => {
  if(role.value[0]) role.value[0].focus()
})
</script>