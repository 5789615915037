import queryString from 'query-string'
import axios from 'axios'

export class API {

    url

    constructor() {
        const qs = queryString.parse(window.location.search)
        const userId = window.location.pathname.substring(1, window.location.pathname.length)
        let currentUrl = new URL(window.location.href)
        this.url = `https://${currentUrl.hostname.replace('verify', 'api')}/v1/users/${userId}/pii?signature=${qs.signature}`
    }

    async getUserData() {
        return axios({
            url: this.url,
            method: 'get'
        }).catch(() => {
            alert('This link is invalid.')
            window.location.href = 'https://infinitegiving.com'
        }).then((response) => {
            return response.data.data
        })
    }

    async save(data) {
        return axios({
            url: this.url,
            method: 'put',
            data
        })
    }
}