<template>
  <div :class="props.class">
    <img src="../assets/logo.png" class="w-16 py-5" />
    <h3 v-if="props.step === 'ssn'" class="font-semibold pb-2">Verify your identity</h3>
    <h3 v-else-if="props.step === 'compliance'" class="font-semibold pb-2">Compliance Questionnaire</h3>

    <nav class="flex pb-5" aria-label="Progress">
      <p class="text-sm font-medium">Step {{ steps.findIndex((step) => step.status === 'current') + 1 }} of {{ steps.length }}</p>
      <ol role="list" class="ml-8 flex items-center space-x-5">
        <li v-for="step in steps" :key="step.name">
          <a v-if="step.status === 'complete'" class="block h-2.5 w-2.5 rounded-full bg-ig-blue hover:bg-indigo-900">
            <span class="sr-only">{{ step.name }}</span>
          </a>
          <a v-else-if="step.status === 'current'" class="relative flex items-center justify-center" aria-current="step">
          <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
            <span class="h-full w-full rounded-full bg-indigo-200" />
          </span>
            <span class="relative block h-2.5 w-2.5 rounded-full bg-ig-blue" aria-hidden="true" />
            <span class="sr-only">{{ step.name }}</span>
          </a>
          <a v-else class="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
            <span class="sr-only">{{ step.name }}</span>
          </a>
        </li>
      </ol>
    </nav>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps(['step'])

const steps = [
  { name: 'ID Upload', status: 'complete' },
  { name: 'SSN Verification', status: props.step === 'ssn' ? 'current' : 'complete' },
  { name: 'Compliance Questionnaire', status: props.step === 'compliance' ? 'current' : 'upcoming' },
]
</script>