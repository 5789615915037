<template>
  <div class="container">
    <div class="flex flex-col justify-center">
      <div class="mx-auto flex items-center justify-center" id="lottie"></div>
      <h3 class="text-base text-center font-semibold leading-6 text-gray-900">Hi {{ props.userFirstName }} 👋</h3>
      <div class="mt-5 mb-10">
        <p class="text-sm text-gray-500">
          To help the government fight financial crime, Federal regulation requires certain financial institutions to obtain, verify, and record information about the beneficial owners of legal entity customers.
          <br/><br/>
          To open an account, we require information that will allow us to identify you and the nonprofit.
          <br/><br/>
          Please grab your driver's license for a quick identity check.
        </p>
      </div>
      <button
          @click="$emit('complete')"
          type="button"
          class="text-white rounded-md w-full bg-ig-blue px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-ig-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ig-blue"
      >
        Scan my driver's license
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted } from 'vue'
import lottie from 'lottie-web'

defineEmits(['complete'])
const props = defineProps(['userFirstName'])

onMounted(() => {
  lottie.loadAnimation({
    container: document.getElementById('lottie'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/license_animation.json'
  })
})
</script>